<template>
  <div>
    <dx-modal title="Lista de espera"
              :value="show" size="lg"
              @input="close" id="modal-waiting-list">
      <div class="loading loading-lg mt-2" v-if="loading" />
      <template v-else>
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" class="form-input" v-focus
                     v-model="search" placeholder="Informe parte do nome para pesquisar...">
              <button class="btn btn-action input-group-btn btn-icon"
                      :class="search ? 'btn-gray' : 'btn-neutral'"
                      tabindex="-1" @click="search = ''">
                <fa-icon :icon="['fal', search ? 'times' : 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="open">
              <fa-icon :icon="['fal', 'plus']"/>Adicionar em espera
            </button>
          </div>
        </div>
        <div class="empty" v-if="filteredItems.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
          </div>
          <p class="empty-title h6">Lista de espera</p>
          <p class="empty-subtitle">
            Nenhum paciente em espera ou verifique o filtro acima.
          </p>
        </div>
        <template v-else>
          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th style="width: 30px">#</th>
              <th>Agenda / Inclusão</th>
              <th>Paciente / Convênio</th>
              <th>Contato / Observação</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in filteredItems" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                <small>Agenda:</small> {{ item.schedule.name }}<br>
                <small>Inclusão:</small> {{ item.date | date('datetime') }}
              </td>
              <td>
                <small>Nome: </small>
                <strong class="text-primary">{{ item.patient.name }}</strong><br>
                <small>Convênio:</small> {{ item.insurance.name }}
              </td>
              <td style="max-width: 130px">
                <div v-if="item.patient.cellphone">
                  <small>Celular:</small> {{ item.patient.cellphone | phone }}
                </div>
                <div v-else>
                  <small>Fixo:</small> {{ item.patient.phone | phone }}
                </div>
                <small>Observação:</small> <small class="text-error">{{ item.notes }}</small>
              </td>
              <td class="text-right">
                <button class="btn btn-icon btn-action btn-sm btn-primary tooltip mr-1"
                        data-tooltip="Agendar" @click="openAppointment(item)">
                  <fa-icon :icon="['fal', 'calendar-day']"/>
                </button>
                <button class="btn btn-icon btn-action btn-sm btn-gray tooltip"
                        data-tooltip="Editar" @click="edit(item.id)">
                  <fa-icon :icon="['fal', 'pencil']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="load-more" v-if="data.items.length > 0">
            <button
              v-if="data.hasMore && !loading"
              class="btn btn-primary"
              @click="loadMore"
              :disabled="loadingMore"
              :class="{ loading: loadingMore }"
            >Carregar mais...</button>
          </div>
        </template>
      </template>
      <template slot="footer">
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Gerenciar paciente em espera"
              id="modal-waiting-list-form" :value="modalForm.show"
              @input="modalForm.show = false">
      <div class="loading loading-lg mt-2" v-if="modalForm.loading" />
      <template v-else>
        <div class="columns">
          <div class="column col-12 form-group"
               :class="{'has-error': $v.modalForm.form.scheduleId.$error}">
            <label class="form-label">Agenda</label>
            <select id="schedule" class="form-select"
                    v-model="modalForm.form.scheduleId" disabled="true"
                    @blur="$v.modalForm.form.scheduleId.$touch()">
              <option value="">Selecione</option>
              <option v-for="item in modalForm.schedules"
                      :value="item.id" :key="item.id">{{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-12 form-group"
               :class="{'has-error': $v.modalForm.form.professionalId.$error}">
            <label class="form-label">Profissional</label>
            <select id="professional" class="form-select"
                    v-model="modalForm.form.professionalId"
                    @change="loadInsurances"
                    @blur="$v.modalForm.form.professionalId.$touch()">
              <option value="">Selecione</option>
              <option v-for="item in modalForm.professionals"
                      :value="item.id" :key="item.id">{{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-12 form-group"
               :class="{'has-error': $v.modalForm.form.patient.name.$error}">
            <label class="form-label">
              Paciente
            </label>
            <dx-autocomplete
              v-model="modalForm.patient"
              :source="findPatient"
              label="name"
              track-by="id"
              @input="setPatient"
              @blur="onPatientBlur"
              placeholder="Nome do paciente, data de nascimento ou CPF"
              input-id="appointment-patient"
              :readonly="!!modalForm.form.patient.id"
              :debounce="800"
              ref="patientSearch">
              <button slot="action" @click="unsetPatient"
                      class="btn btn-action input-group-btn btn-icon"
                      :class="modalForm.patient ? 'btn-gray' : 'btn-neutral'"
                      tabindex="-1">
                <fa-icon :icon="['fal', modalForm.patient ? 'times' : 'search']"></fa-icon>
              </button>
              <template v-slot="{ item }">
                <a v-if="item.id">{{ item.name }} ({{ item.birthDate | date }})</a>
                <a v-else>{{ item.name }} (não cadastrado)</a>
              </template>
            </dx-autocomplete>
          </div>
          <div class="column col-12 form-group"
               :class="{'has-error': $v.modalForm.form.patient.insurancePlanId.$error}">
            <label class="form-label">Convênio</label>
            <select id="insurance" class="form-select"
                    @blur="$v.modalForm.form.patient.insurancePlanId.$touch()"
                    v-model="modalForm.form.patient.insurancePlanId">
              <option value="">Selecione</option>
              <option v-for="(item, i) in modalForm.insurances"
                      :value="item.plan.id" :key="i">{{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-6 form-group"
               :class="{'has-error': $v.modalForm.form.patient.cellphone.$error}">
            <label for="cellphone" class="form-label">Celular</label>
            <input type="text" id="cellphone" class="form-input"
                   v-model="modalForm.form.patient.cellphone"
                   @blur="$v.modalForm.form.patient.cellphone.$touch()"
                   placeholder="(00) 0 0000-0000" v-mask-phone.br>
          </div>
          <div class="column col-6 form-group"
               :class="{'has-error': $v.modalForm.form.patient.phone.$error}">
            <label for="phone" class="form-label">Fixo</label>
            <input type="text" id="phone" class="form-input"
                   v-model="modalForm.form.patient.phone"
                   @blur="$v.modalForm.form.patient.phone.$touch()"
                   placeholder="(00) 0000-0000" v-mask-phone.br>
          </div>
          <div class="column col-12 form-group">
            <label class="form-label">Observações</label>
            <textarea id="notes" rows="2" class="form-input"
                      v-model="modalForm.form.notes"/>
          </div>
        </div>
      </template>
      <template slot="footer">
        <template v-if="!modalForm.loading">
          <button class="btn btn-error float-left"
                  @click="remove" v-if="modalForm.form.id"
                  :class="{loading: modalForm.deleting}"
                  :disabled="modalForm.deleting || modalForm.saving">Excluir</button>
          <button class="btn btn-primary mr-1" @click="save"
                  :class="{loading: modalForm.saving}"
                  :disabled="modalForm.saving || modalForm.deleting">Salvar</button>
        </template>
        <button class="btn" @click="modalForm.show = false">Sair</button>
      </template>
    </dx-modal>
    <dx-modal
      :title="`Selecione o dia e horário para agendamento de ${modalAppointment.form.patient.name}`"
      id="modal-waiting-list-hours" size="lg" :value="modalAppointment.show"
      @input="modalAppointment.show = false">
      <div class="columns">
        <div class="column col-4 form-group">
          <label class="form-label">Agenda</label>
          <input type="text" class="form-input" readonly
                 v-model="modalAppointment.form.schedule.name">
        </div>
        <div class="column col-4 form-group">
          <label class="form-label">Profissional</label>
          <input type="text" class="form-input" readonly
                 v-model="modalAppointment.form.professional.name">
        </div>
        <div class="column form-group">
          <label class="form-label">Período</label>
          <select id="replicate-period" class="form-select"
                  v-model="modalAppointment.period">
            <option value="7">Próximos 7 dias</option>
            <option value="15">Próximos 15 dias</option>
            <option value="30">Próximos 30 dias</option>
          </select>
        </div>
        <div class="column col-auto form-group mb-2"
             style="display: flex; align-items: flex-end">
          <button class="btn btn-gray btn-icon btn-icon-left input-group-btn"
                  :disabled="modalAppointment.loading"
                  @click="loadAppointments">
            <fa-icon :icon="['fal', 'search']"/> Pesquisar
          </button>
        </div>
      </div>
      <div class="loading loading-lg mt-2"
           v-if="modalAppointment.loading || modalAppointment.saving" />
      <template v-else>
        <div class="divider"></div>
        <div class="appointment-list">
          <div class="empty mt-2" v-if="modalAppointment.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Agendamentos</p>
            <p class="empty-subtitle">
              Nenhum horário disponível no período informado
            </p>
          </div>
          <div class="scroll-list-wrapper">
            <div class="scroll-list">
              <div class="replicate-items" v-for="(schedule, i) in modalAppointment.items" :key="i">
                <div class="text-bold">
                  {{ schedule.date | date('DD[ de ]MMMM[ de ]YYYY[ (]dddd[)]') }}
                </div>
                <div class="hour-item">
                  <button class="btn btn-secondary hour-item-hours"
                          @click="saveAppointment(schedule, hour)"
                          v-for="(hour, i) in schedule.hours" :key="i">
                    {{ hour }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <button class="btn" @click="modalAppointment.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import fuzzy from 'fuzzy';
import formMixin from 'src/mixins/form';
import { required } from 'vuelidate/lib/validators';
import { phone } from '@/data/validators';
import moment from 'moment';
import { mergeFrom } from '@/helpers/object';

export default {
  mixins: [formMixin],
  props: {
    show: {
      type: Boolean,
    },
    scheduleId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      loadingMore: false,
      search: '',
      data: {
        items: [],
        hasMore: false,
        limit: 15,
        offset: 0,
      },
      modalForm: {
        show: false,
        loading: false,
        saving: false,
        deleting: false,
        patient: null,
        schedules: this.$store.state.appointment.filter.schedules,
        professionals: [],
        insurances: [],
        form: this.blankForm(),
      },
      modalAppointment: {
        show: false,
        loading: false,
        saving: false,
        period: '7',
        items: [],
        form: this.blankAppointmentForm(),
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    filteredItems() {
      let { items } = this.data;
      if (this.search.trim()) {
        const results = fuzzy.filter(this.search, items, {
          extract: (el) => {
            if (el.patient.name) {
              return this.removeAccentuation(el.patient.name);
            }
            return '';
          },
        });
        items = results.map(({ original }) => original);
      }

      return items;
    },
  },
  validations() {
    return {
      modalForm: {
        form: {
          scheduleId: { required },
          professionalId: { required },
          patient: {
            name: { required },
            insurancePlanId: { required },
            phone: { phone },
            cellphone: { required, phone },
          },
        },
      },
    };
  },
  methods: {
    reload() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        scheduleId: this.scheduleId,
      };

      return this.$http.get('/waiting-list', { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    close() {
      this.$emit('close');
    },
    open() {
      this.$v.modalForm.form.$reset();
      this.modalForm.form = this.blankForm();
      this.loadProfessionals();
      this.modalForm.id = null;
      this.modalForm.show = true;
    },
    edit(id) {
      this.modalForm.loading = true;
      this.modalForm.show = true;
      this.loadProfessionals();

      return this.$http.get(`/waiting-list/${id}`)
        .then(({ data }) => {
          this.modalForm.patient = {
            id: data.patient.id,
            name: data.patient.name,
          };
          this.modalForm.form = {
            id: data.id,
            scheduleId: data.schedule.id,
            professionalId: data.professional.id,
            patient: {
              id: data.patient.id,
              name: data.patient.name,
              phone: data.patient.phone,
              cellphone: data.patient.cellphone,
              insurancePlanId: data.insurance.plan.id,
            },
            notes: data.notes,
          };
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.modalForm.loading = false;
        });
    },
    setPatient(patient) {
      if (patient && patient.id) {
        this.$http.get(`/patients/${patient.id}`)
          .then(({ data }) => {
            this.modalForm.form.patient.id = data.id;
            this.modalForm.form.patient.name = data.name;
            this.modalForm.form.patient.phone = data.phone;
            this.modalForm.form.patient.cellphone = data.cellphone;
            const insurance = this.modalForm.insurances
              .find(({ plan }) => plan.id === data.patient.insurance.planId);
            if (insurance) {
              this.modalForm.form.patient.insurancePlanId = insurance.plan.id;
            }
          })
          .catch(() => {});
      }
    },
    unsetPatient() {
      this.modalForm.patient = null;
      this.modalForm.form.patient = this.blankForm().patient;
    },
    loadProfessionals() {
      this.unsetPatient();
      return this.$http
        .get(`/schedules/${this.modalForm.form.scheduleId}/professionals`)
        .then(({ data }) => {
          this.modalForm.professionals = data.items;
          if (data.items.length === 1) {
            this.modalForm.form.professionalId = data.items[0].id;
            this.loadInsurances();
          }
        })
        .catch(() => {});
    },
    loadInsurances() {
      if (!this.modalForm.form.professionalId) {
        return;
      }
      this.modalForm.insurances = this.modalForm.professionals
        .find(({ id }) => id === this.modalForm.form.professionalId).insurances;
    },
    findPatient(search) {
      return this.$http.get(`/patients?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    onPatientBlur(text) {
      this.modalForm.form.patient.name = text;
      this.$v.modalForm.form.scheduleId.$touch();
    },
    save() {
      this.$v.modalForm.form.$touch();
      if (this.$v.modalForm.form.$error || this.modalForm.saving) {
        return;
      }

      this.modalForm.saving = true;

      const data = this.clone(this.modalForm.form);

      const request = !data.id
        ? this.$http.post('/waiting-list', data)
        : this.$http.put(`/waiting-list/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(() => {
          this.$toast.show('Registro salvo!');
          this.modalForm.show = false;
          this.reload();
        })
        .catch(() => {})
        .finally(() => {
          this.modalForm.saving = false;
        });
    },
    remove() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              close();
              this.modalForm.deleting = true;
              this.$http.delete(`/waiting-list/${this.modalForm.form.id}`)
                .catch(() => {})
                .finally(() => {
                  this.modalForm.show = false;
                  this.modalForm.deleting = false;
                  this.reload();
                });
            },
          },
        ],
      });
    },
    openAppointment(item) {
      this.modalAppointment.form = mergeFrom(this.blankAppointmentForm(), item);
      this.modalAppointment.show = true;
      this.loadAppointments();
    },
    loadAppointments() {
      this.modalAppointment.loading = true;
      this.modalAppointment.items = [];

      const params = {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(this.modalAppointment.period, 'days').format('YYYY-MM-DD'),
        scheduleId: this.modalAppointment.form.schedule.id,
        professionalIds: this.modalAppointment.form.professional.id,
      };

      return this.$http
        .get('/calendar', { params })
        .then(({ data }) => {
          if (data.items && data.items.length > 0) {
            const items = data.items.filter(appointment => (
              (!appointment.isArrivalOrder && appointment.status === 'available')
            ));

            items.forEach((appointment) => {
              const professional = appointment.available.professionals
                .find(({ id }) => id === this.modalAppointment.form.professional.id);

              const specialtyCode = professional.specialties
                && professional.specialties.length > 0
                ? professional.specialties[0]
                : null;

              const startDate = appointment.startDate.substr(0, 10);
              const startHour = appointment.startDate.substr(11, 5);

              const found = this.modalAppointment.items.find(({ date }) => date === startDate);

              if (!found) {
                this.modalAppointment.items.push({
                  date: startDate,
                  scheduleId: appointment.scheduleId,
                  professionalId: professional.id,
                  specialtyCode,
                  hours: [startHour],
                });
              } else {
                found.hours.push(startHour);
              }
            });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.modalAppointment.loading = false;
        });
    },
    saveAppointment(schedule, hour) {
      if (this.modalAppointment.saving) {
        return;
      }

      this.$dialog.show('', {
        html:
          '<div>'
          + '<h5 class="text-center">Agendamento</h5>'
          + '<div class="text-center">Deseja realmente realizar este agendamento?</div>'
          + '<div class="divider"></div>'
          + '<table class="table">'
          + '<tbody>'
          + '<tr>'
          + '<td>Data e hora:</td>'
          + `<td class="text-bold">${moment(schedule.date).format('DD/MM/YYYY')} ${hour}</td>`
          + '</tr>'
          + '<tr>'
          + '<td>Agenda:</td>'
          + `<td class="text-bold">${this.modalAppointment.form.schedule.name}</td>`
          + '</tr>'
          + '<tr>'
          + '<td>Profissional:</td>'
          + `<td class="text-bold">${this.modalAppointment.form.professional.name}</td>`
          + '</tr>'
          + '<tr>'
          + '<td>Paciente:</td>'
          + `<td class="text-bold">${this.modalAppointment.form.patient.name}</td>`
          + '</tr>'
          + '<tr>'
          + '<td>Convênio:</td>'
          + `<td class="text-bold">${this.modalAppointment.form.insurance.name}</td>`
          + '</tr>'
          + '</tbody>'
          + '</table>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              close();
              this.modalAppointment.saving = true;
              const data = {
                date: schedule.date,
                hour,
                scheduleId: schedule.scheduleId,
                professionalId: schedule.professionalId,
                specialtyCode: schedule.specialtyCode,
              };
              this.$http
                .post(`/waiting-list/${this.modalAppointment.form.id}/appointments`, data)
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.modalAppointment.show = false;
                  this.modalAppointment.saving = false;
                  this.reload();
                });
            },
          },
        ],
      });
    },
    blankForm() {
      return {
        id: '',
        scheduleId: this.scheduleId,
        professionalId: '',
        patient: {
          id: '',
          name: '',
          phone: '',
          cellphone: '',
          insurancePlanId: '',
        },
        notes: '',
      };
    },
    blankAppointmentForm() {
      return {
        id: '',
        date: '',
        schedule: {
          id: '',
          name: '',
        },
        professional: {
          id: '',
          name: '',
          specialtyCode: '',
        },
        patient: {
          id: '',
          name: '',
          phone: '',
          cellphone: '',
        },
        insurance: {
          id: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
        },
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  @import '../../../../assets/scss/mixins';

  #modal-waiting-list {
    .load-more {
      margin: $layout-spacing-lg 0;
      text-align: center;
      .btn {
        padding: 0 $layout-spacing-xl;
      }
    }
  }

  #modal-waiting-list-hours {
    .appointment-list {
      display: flex;
      flex-direction: column;
      min-height: 10rem;
      overflow-y: auto;
      .scroll-list-wrapper {
        flex-grow: 1;
        position: relative;
      }
      .scroll-list {
        bottom: 0;
        overflow-y: auto;
        padding-right: $layout-spacing;
        position: absolute;
        top: 0;
        width: 100%;
        @include scroll-bar();
      }
      .replicate-items {
        margin-bottom: $layout-spacing-lg;
        .hour-item {
          background-color: $gray-color-ultra-light;
          border: $border-width solid $border-color;
          border-radius: $border-radius;
          margin-top: $layout-spacing-sm;
          padding: $layout-spacing;
          .hour-item-hours {
            border: 0;
            font-size: $font-size-sm;
            height: auto;
            margin: $layout-spacing-sm;
            width: 3rem;
          }
        }
      }
    }
  }
</style>
