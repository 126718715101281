<template>
  <div class="appointment-modal">
    <form autocomplete="off" @submit.stop>
      <div class="columns">
        <!-- Hour -->
        <div class="column col-2 form-group" :class="{'has-error': $v.hour.$error}">
          <label for="appointment-hour" class="form-label">Horário</label>
          <div class="input-group">
            <dx-dropdown :items="statuses" label="name" class="input-group-addon"
                         @select="setStatus" :max-height="220" :disabled="isFinished">
              <span class="square c-hand" title="Status"
                    :style="{backgroundColor: statusColor}"></span>
              <template v-slot:item="{ item }">
                <div class="tile tile-centered c-hand">
                  <div class="tile-icon">
                    <span class="square" :style="{backgroundColor: item.color}"></span>
                  </div>
                  <div class="tile-content">{{ item.name }}</div>
                </div>
              </template>
            </dx-dropdown>
            <input type="text" id="appointment-hour" class="form-input text-center"
                   v-model="hour" @change="$v.hour.$touch()"
                   v-mask="'00:00'" placeholder="00:00" :readonly="isReadonly">
          </div>
          <template v-if="$v.hour.$error">
            <div class="form-input-hint"
                 v-if="!$v.hour.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.hour.hour">Hora inválida</div>
          </template>
        </div>

        <!-- Schedule -->
        <div class="column col-4 form-group"
             :class="{'has-error': $v.form.scheduleId.$error}">
          <label for="appointment-schedule" class="form-label">Agenda</label>
          <select id="appointment-schedule" class="form-select"
                  :class="{ readonly: isReadonly }"
                  v-model="form.scheduleId" :disabled="isReadonly || isFinished">
            <option value="">[Selecione a agenda]</option>
            <template v-if="schedules.length > 0">
              <option v-for="item in schedules"
                      :value="item.id" :key="item.id">{{ item.name }}</option>
            </template>
            <template v-else>
              <option :value="null">{{ noSchedulesLabel }}</option>
            </template>
          </select>
          <template v-if="$v.form.scheduleId.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.scheduleId.required">Campo obrigatório</div>
          </template>
        </div>
        <!-- Professional -->
        <div class="column col-6 form-group"
             :class="{'has-error': $v.form.professional.id.$error}">
          <label for="appointment-professional" class="form-label">Profissional</label>
          <select id="appointment-professional" class="form-select"
                  @blur="$v.form.professional.id.$touch()"
                  @change="validateScheduleInfo"
                  :disabled="isFinished"
                  v-model="professionalSpecialtyKey">
            <option value="">[Selecione o profissional]</option>
            <option
              v-for="item in availableProfessionalsSpecialty"
              :value="item.key"
              :key="item.key">
              {{ item.name }}
              <template v-if="item.specialty">({{ item.specialty.name }})</template>
            </option>
          </select>
          <template v-if="$v.form.professional.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.professional.id.required">Campo obrigatório</div>
          </template>
        </div>

        <!-- Patient -->
        <div class="column col-6 form-group" :class="{
          'has-error': $v.form.patient.name.$error,
          'patient-not-registered': isPatientNotRegistered
        }">
          <label :for="'appointment-patient'" class="form-label">Paciente</label>
          <dx-autocomplete
            v-model="patient"
            :source="findPatient"
            label="name"
            track-by="id"
            @input="setPatient"
            @focus="onPatientFocus"
            @blur="onPatientBlur"
            placeholder="Nome do paciente, data de nascimento ou CPF"
            input-id="appointment-patient"
            :debounce="800"
            :readonly="!!form.patient.id || isBlockedByAnotherUser"
            ref="patientSearch"
          >
            <template v-slot:action>
              <button
                v-if="isPatientNotRegistered"
                @click="switchTab('patient')"
                class="btn btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
              <button
                v-else
                @click="unsetAppointment"
                class="btn btn-action input-group-btn btn-icon"
                :class="patient ? 'btn-gray' : 'btn-neutral'"
                :disabled="hasAccount || isFinished"
                tabindex="-1">
                <fa-icon :icon="['fal', patient ? 'times' : 'search']"></fa-icon>
              </button>
            </template>
            <template v-slot="{ item }">
              <a v-if="item.id">{{ item.name }} ({{ item.birthDate | date }})</a>
              <a v-else>{{ item.name }} (não cadastrado)</a>
            </template>
          </dx-autocomplete>
          <template v-if="$v.form.patient.name.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.name.required">Campo obrigatório</div>
          </template>
        </div>

        <!-- Birthdate -->
        <div class="column col-4 form-group"
             :class="{'has-error': $v.form.patient.birthDate.$error}">
          <label class="form-label">Nascimento</label>
          <div class="input-group">
            <dx-input-date
              id="appointment-patient-birth-date"
              class="form-input"
              v-model="form.patient.birthDate"
              :readonly="!!form.patient.id"
              @blur="$v.form.patient.birthDate.$touch()"
            />
            <span class="input-group-addon" v-if="dateOld">{{ dateOld }}</span>
          </div>
          <template v-if="$v.form.patient.birthDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.birthDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.birthDate.maxDate">
              Data não pode ser superior a hoje
            </div>
          </template>
        </div>

        <!-- Phone -->
        <div class="column col-2 form-group"
             :class="{'has-error': $v.form.patient.cellphone.$error}">
          <label for="appointment-patient-phone" class="form-label">Telefone</label>
          <input type="text" id="appointment-patient-phone"
                 class="form-input" placeholder="(00) 0 0000-0000"
                 v-model="form.patient.cellphone"
                 @blur="$v.form.patient.cellphone.$touch()"
                 v-mask-phone.br>
          <template v-if="$v.form.patient.cellphone.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.cellphone.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.cellphone.phone">Telefone inválido</div>
          </template>
        </div>
      </div>
      <div class="columns">
        <!-- Insurance -->
        <div class="column col-5 form-group"
             :class="{'has-error': $v.form.insurance.plan.id.$error}">
          <label class="form-label">Convênio</label>
          <div :class="hasExpenses ? 'tooltip' : ''"
               data-tooltip="Existem procedimentos para este convênio">
            <select id="appointment-insurance" class="form-select"
                    v-model="form.insurance.plan.id" :disabled="hasExpenses"
                    @change="changeInsurance" @blur="$v.form.insurance.plan.id.$touch()">
              <option value="">[Selecione o convênio]</option>
              <option v-for="(item, i) in availableInsurances"
                      :value="item.plan.id" :key="i">{{ item.customName }}</option>
            </select>
          </div>
          <template v-if="$v.form.insurance.plan.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.insurance.plan.id.required">Campo obrigatório</div>
          </template>
        </div>

        <!-- Insurance record -->
        <div class="column col-3 form-group"
             :class="{'has-error': $v.form.insurance.record.$error}">
          <label for="appointment-insurance-record" class="form-label">Carteira</label>
          <input type="text" id="appointment-insurance-record" class="form-input"
                 @change="changeRecord" placeholder="000000000"
                 @blur="$v.form.insurance.record.$touch()"
                 v-mask="'AAAAAAAAAAAAAAAAAAAA'" v-model="form.insurance.record">
          <template v-if="$v.form.insurance.record.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.insurance.record.required">
              Campo obrigatório
            </div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.insurance.record.minLength">
              A carteira deve ter no mínimo {{form.insurance.plan.settings.record.digits.min}}
              dígitos
            </div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.insurance.record.maxLength">
              A carteira deve ter no máximo {{form.insurance.plan.settings.record.digits.max}}
              dígitos
            </div>
          </template>
        </div>

        <!-- Insurance validity -->
        <div class="column col-2 form-group"
             :class="{'has-error': $v.form.insurance.validity.$error}">
          <label class="form-label">Validade</label>
          <dx-input-date
            id="appointment-insurance-validity" class="form-input" placeholder="00/00/0000"
            v-model="form.insurance.validity"
            @focus="fillInsuredValidityWithServiceDate"
            @change="changeValidity"
            @blur="$v.form.insurance.validity.$touch()"
          />
          <template v-if="$v.form.insurance.validity.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.insurance.validity.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.insurance.validity.minDate">
              Carteirinha vencida
            </div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.insurance.validity.required">Campo Obrigatório</div>
          </template>
        </div>

        <!-- Reserves -->
        <div class="column col-2 form-group">
          <label class="form-label">Horários</label>
          <st-input-number-step
            id="appointment-quantity"
            v-model="form.reserves"
            :min-value="1"
            :max-value="10"
            :readonly="!!form.id || form.slot"
          />
        </div>

        <!-- Client aso -->
        <div class="column col-12 form-group"
             :class="{'has-error': $v.form.asoClient.id.$error}"
             v-if="requiredAsoClient">
          <label class="form-label">Cliente ASO</label>
          <dx-autocomplete
            v-model="asoClient"
            label="name"
            :source="findAsoClient"
            @select="setAsoClient"
            :readonly="!!form.asoClient.id"
            :debounce="800"
            @blur="$v.form.asoClient.id.$touch()"
            placeholder="Pesquisar cliente">
            <button slot="action" @click="unsetAsoClient"
                    class="btn btn-action input-group-btn btn-icon"
                    :class="asoClient ? 'btn-gray' : 'btn-neutral'"
                    tabindex="-1">
              <fa-icon :icon="['fal', asoClient ? 'times' : 'search']" />
            </button>
            <template v-slot="{ item }">
              <strong class="text-primary text-uppercase">
                {{ item.name }}
              </strong>
              <span class="mr-1" v-if="item.identity">
                - {{ item.identity.value | cnpj }}
              </span>
            </template>
          </dx-autocomplete>
          <template v-if="$v.form.asoClient.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.asoClient.id.required">Campo Obrigatório</div>
          </template>
        </div>

        <!-- Notes -->
        <div class="column col-12 form-group">
          <label class="form-label">Anotações</label>
          <textarea
            id="appointment-notes"
            class="form-input"
            :rows="scheduleNotes ? 3 : 4"
            v-model="form.notes"
          ></textarea>
          <div
            v-if="scheduleNotes"
            class="schedule-notes"
            title="Observações da agenda"
          >
            <div class="schedule-notes-content">{{ scheduleNotes }}</div>
          </div>
        </div>
      </div>
      <div class="alert alert-warning mt-2" v-if="isInUseByAnotherUser">
        <fa-icon :icon="['far', 'exclamation-triangle']"></fa-icon>
        Este horário está em uso por outro(a) atendente
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/src/validators';
import {
  date,
  phone,
  hour,
  maxDate,
  minDate,
} from 'src/data/validators';
import { APPOINTMENT_NO_SCHEDULES } from '@/data/contants';
import formMixin from '@/mixins/form';
import appointmentStatuses, { getColor } from '@/data/appointment-statuses';
import { mergeFrom } from '@/helpers/object';
import dateOld from '@/filters/date-old';
import moment from 'moment';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';

export default {
  mixins: [formMixin],
  data() {
    return {
      statuses: appointmentStatuses,
      patient: null,
      asoClient: null,
      hasDifferentInsurance: false,
      form: this.$store.state.appointment.form,
      noSchedulesLabel: APPOINTMENT_NO_SCHEDULES,
    };
  },
  mounted() {
    if (this.form.slot) {
      this.updateAvailableData();
    }
    if (this.form.patient.name) {
      this.patient = {
        id: this.form.patient.id,
        name: this.form.patient.name,
      };
    }

    this.$store.state.appointment.form.patient.insurance.plan
      .settings = this.blankFormPlanSettings();
    if (this.form.insurance.plan.id) this.loadPlanSettings();

    if (this.form.asoClient && this.form.asoClient.id) {
      this.asoClient = this.form.asoClient;
    }

    this.$nextTick(() => {
      if (!this.isReadonly) {
        const hourEl = document.getElementById('appointment-hour');
        hourEl.select();
        hourEl.focus();
      } else {
        document.getElementById('appointment-patient').focus();
      }
    });
    this.validateScheduleInfo();
  },
  validations() {
    const rules = {
      hour: { required, hour },
      form: {
        scheduleId: { required },
        professional: {
          id: { required },
        },
        patient: {
          name: { required },
          birthDate: { date, maxDate: maxDate() },
          cellphone: { required, phone },
          insurance: {},
        },
        insurance: {
          record: {},
          validity: {
            date,
            minDate: minDate(moment(this.form.startDate).format('YYYY-MM-DD')),
          },
          plan: {
            id: { required },
          },
        },
        asoClient: {
          id: {},
        },
      },
    };

    if (this.form.insurance.plan.settings.record) {
      const isToday = moment(this.form.startDate).isSame(moment(), 'day');

      if (this.form.id && isToday) {
        if (this.form.insurance.plan.settings.record.required) {
          rules.form.insurance.record = { required };
        }

        if (this.form.insurance.plan.settings.record.digits.enabled) {
          rules.form.insurance.record = {
            required,
            minLength: minLength(this.form.insurance.plan.settings.record.digits.min),
            maxLength: maxLength(this.form.insurance.plan.settings.record.digits.max),
          };
        }

        if (this.form.insurance.plan.settings.record.requiredValidity) {
          rules.form.insurance.validity = {
            date,
            required,
            minDate: minDate(moment(this.form.startDate).format('YYYY-MM-DD')),
          };
        }
      }
    }

    if (this.requiredAsoClient) {
      rules.form.asoClient.id = { required };
    }

    return rules;
  },
  watch: {
    'form.patient.name': {
      handler() {
        this.patient = {
          id: this.form.patient.id,
          name: this.form.patient.name,
        };
      },
    },
    'form.scheduleId': function watchScheduleId() {
      this.updateAvailableData();
    },
    'form.insurance.plan.id': function findInsuranceId() {
      const insurance = this.availableInsurances
        .find(({ plan }) => plan.id === this.form.insurance.plan.id);
      this.form.insurance.id = insurance ? insurance.id : '';
    },
  },
  computed: {
    ...mapState({
      appointmentState: state => state.appointment,
      schedules: state => state.appointment.schedules,
      user: state => state.auth.user,
    }),
    ...mapGetters({
      getProfessionalById: 'getProfessionalById',
      getInsuranceByPlanId: 'getInsuranceByPlanId',
      isInUseByAnotherUser: 'isInUseByAnotherUser',
      isBlockedByAnotherUser: 'isBlockedByAnotherUser',
    }),
    isPatientNotRegistered() {
      return !!this.form.id && !this.form.patient.id && !!this.form.patient.name;
    },
    isReadonly() {
      return !this.form.slot || !!this.form.id;
    },
    hasExpenses() {
      return this.form.expenses && this.form.expenses.length > 0;
    },
    hasAccount() {
      return this.hasExpenses && this.form.expenses.some(item => !!item.accountId);
    },
    requiredAsoClient() {
      return this.form.insurance.plan
        && this.form.insurance.plan.settings
        && this.form.insurance.plan.settings.aso
        && this.form.insurance.plan.settings.aso.requiredClient;
    },
    hour: {
      get() {
        return this.form.startDate.split('T')[1];
      },
      set(value) {
        this.form.startDate = [
          this.form.startDate.split('T')[0],
          value,
        ].join('T');
      },
    },
    professionalSpecialtyKey: {
      get() {
        if (this.form.professional.id) {
          return [
            this.form.professional.id,
            this.form.professional.specialty || '',
          ].join('-');
        }
        return '';
      },
      set(value) {
        [
          this.form.professional.id,
          this.form.professional.specialty,
        ] = value.split('-');
      },
    },
    dateOld() {
      return dateOld(this.form.patient.birthDate, true);
    },
    statusColor() {
      return getColor(this.form.status);
    },
    availableProfessionals() {
      return this.appointmentState.available.professionals
        .map(({ id, specialties }) => this.getProfessionalById(id, specialties));
    },
    availableProfessionalsSpecialty() {
      return this.availableProfessionals
        .reduce((result, professional) => {
          const specialties = professional.specialties.length > 0
            ? professional.specialties
            : [null];

          specialties.forEach((specialty) => {
            result.push({
              key: `${professional.id}-${specialty ? specialty.code : ''}`,
              id: professional.id,
              name: professional.name,
              council: professional.council,
              specialty,
            });
          });

          return result;
        }, []);
    },
    availableInsurances() {
      return this.appointmentState.available.insurancePlans
        .map(this.getInsuranceByPlanId);
    },
    scheduleNotes() {
      return this.form.schedule ? this.form.schedule.notes : '';
    },
    isFinished() {
      const allowEditFinishedAppointment = this.user.branch.settings
      && this.user.branch.settings['clinical.allowEditFinishedAppointment']
        ? this.user.branch.settings['clinical.allowEditFinishedAppointment']
        : false;

      return !!this.form.service.finishedAt && allowEditFinishedAppointment;
    },
  },
  methods: {
    ...mapMutations({
      updateAvailableData: 'Appointment.UPDATE_AVAILABLE_DATA',
    }),
    validate() {
      this.$v.$touch();
      return !this.$v.$error;
    },
    setStatus({ key }) {
      this.form.status = key;
    },
    onPatientFocus() {
      this.$v.form.patient.name.$reset();
    },
    onPatientBlur(text) {
      this.form.patient.name = text;
      this.$v.form.patient.name.$touch();
    },
    fillInsuredValidityWithServiceDate() {
      if (this.form.insurance.plan.settings.record.fillValidityWithServiceDate) {
        this.form.insurance.validity = moment(this.form.startDate)
          .format('YYYY-MM-DD');
      }
    },
    findPatient(search) {
      const params = {
        notFilterBranch: true,
        search,
      };

      return this.$http.get('/patients', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setPatient(patient) {
      const mergeData = patient || this.$store.state.appointment.blankForm().patient;
      mergeFrom(this.form.patient, mergeData);

      const address = patient && patient.addresses && patient.addresses.length > 0
        ? patient.addresses[0] : null;
      if (address) {
        this.form.patient.address = {
          id: address.id,
          type: address.type,
          postalCode: address.postalCode,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
        };
      }

      if (patient && patient.id) {
        this.$http.get(`/patients/${patient.id}`)
          .then(({ data }) => {
            if (data && data.alert) {
              this.$notification.show('Atenção', {
                type: 'warning',
                timeout: 10000,
                content: data.alert,
              });
            }

            const planIsValid = this.availableInsurances
              .some(({ plan }) => plan.id === data.patient.insurance.planId);
            if (planIsValid) {
              this.form.patient.insurance = data.patient.insurance;
              this.form.insurance.id = data.patient.insurance.id;
              this.form.insurance.record = data.patient.insurance.record;
              this.form.insurance.validity = data.patient.insurance.validity;
              this.form.insurance.plan.id = data.patient.insurance.planId;
            } else {
              const insurance = this.availableInsurances
                .find(({ id }) => id === data.patient.insurance.id);
              if (insurance) {
                this.form.insurance.id = insurance.id;
                this.form.insurance.plan.id = insurance.plan.id;
              }
            }
            if (this.form.insurance.plan.id) this.loadPlanSettings();
          })
          .catch(() => {});

        this.$emit('patient-alert', {
          patientId: this.form.patient.id,
          patientName: this.form.patient.name,
          displayTo: 'appointment',
        });
      }
      this.validateScheduleInfo();
    },
    unsetAppointment() {
      this.unsetPatient();
      const emptyForm = this.$store.state.appointment.blankForm();
      this.form.insurance = emptyForm.insurance;
      this.form.expenses = emptyForm.expenses;
    },
    unsetPatient() {
      this.setPatient(null);
      this.info = null;
      this.patient = null;
    },
    updateInsurance() {
      this.form.patient.insurance = this.clone(this.form.insurance);
    },
    switchTab(id) {
      this.$emit('switch-tab', id);
    },
    findAsoClient(search) {
      const params = {
        limit: 30,
        active: true,
        search,
      };

      return this.$http.get('/clients', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setAsoClient(data) {
      this.asoClient = {
        id: data.id,
        name: data.name,
      };
      this.form.asoClient = this.asoClient;
    },
    unsetAsoClient() {
      this.asoClient = null;
      this.form.asoClient = { id: '', name: '' };
    },
    changeInsurance() {
      this.validateScheduleInfo();
      if (String(this.$store.state.appointment.form.patient.insurance.plan.id)
        === String(this.form.insurance.plan.id)) {
        this.form.insurance.record = this.$store.state.appointment.form.patient.insurance.record;
        this.form.insurance.validity = this.$store.state.appointment.form.patient.insurance
          .validity;
      } else {
        this.form.insurance.record = '';
        this.form.insurance.validity = '';
      }
      this.loadPlanSettings();
    },
    validateScheduleInfo() {
      this.$emit('validateScheduleInfo');
    },
    changeRecord() {
      if (String(this.$store.state.appointment.form.patient.insurance.plan.id)
        === String(this.form.insurance.plan.id)) {
        this.$store.state.appointment.form.patient.insurance.record = this.form.insurance.record;
      }
    },
    changeValidity() {
      if (String(this.$store.state.appointment.form.patient.insurance.plan.id)
        === String(this.form.insurance.plan.id)) {
        this.$store.state.appointment.form.patient.insurance
          .validity = this.form.insurance.validity;
      }
    },
    async loadPlanSettings() {
      this.loading = true;
      const insurancePlan = this.availableInsurances.find(
        item => item.plan.id === this.form.insurance.plan.id,
      );
      if (insurancePlan && insurancePlan.plan && insurancePlan.plan.id) {
        await this.$http.get(
          `/insurances/${insurancePlan.id}/plans/${insurancePlan.plan.id}`,
        )
          .then(({ data }) => {
            this.$store.state.appointment.form.insurance.plan.settings.id = insurancePlan.plan.id;
            if (data.insurance) {
              this.$store.state.appointment.form.insurance.type = data.insurance.type;
            }
            if (data.settings.tiss) {
              this.$store.state.appointment.form.insurance.plan.settings
                .tiss.enabled = data.settings.tiss.enabled;
              this.$store.state.appointment.form.insurance.plan.settings
                .tiss.consultationInSadt = data.settings.tiss.consultation.sadt;
            }
            if (data.settings.general) {
              if (data.settings.general.record) {
                this.$store.state.appointment.form.insurance.plan.settings.record
                  .required = data.settings.general.record.required;
                this.$store.state.appointment.form.insurance.plan.settings.record
                  .requiredValidity = data.settings.general.record.requiredValidity;
                this.$store.state.appointment.form.insurance.plan.settings.record
                  .fillValidityWithServiceDate = data.settings.general
                    .record.fillValidityWithServiceDate;
                if (data.settings.general.record.digits) {
                  this.$store.state.appointment.form.insurance.plan.settings.record.digits = data
                    .settings.general.record.digits;
                }
              }
              this.$store.state.appointment.form.insurance.plan
                .settings.aso.requiredClient = data.settings.general.aso
                && data.settings.general.aso.requiredClient;
            }
          })
          .catch((e) => {
            if (e.response
              && e.response.data
              && e.response.data.message) {
              this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    blankFormPlanSettings() {
      return {
        id: '',
        tiss: {
          enabled: false,
          consultationInSadt: false,
        },
        record: {
          required: false,
          requiredValidity: false,
          digits: {
            enabled: false,
            min: 0,
            max: 0,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import 'src/assets/scss/colors';

.appointment-modal {
  .dropdown {
    .tile-content {
      line-height: $line-height;
    }
  }
  .patient-not-registered {
    .form-input {
      border-color: $orange-color-500;
    }
    .btn {
      background-color: $orange-color-500;
      border-color: $orange-color-500;
      color: $light-color
    }
  }
  .schedule-notes {
    border: $border-width solid $border-color-dark;
    border-radius: $border-radius;
    margin-top: $layout-spacing;
    .schedule-notes-content {
      color: $info-color;
      font-size: $font-size-xs;
      font-weight: bold;
      max-height: 2.7rem;
      overflow-y: auto;
      padding: $layout-spacing-sm $layout-spacing;
      white-space: pre-wrap;
    }
  }
}
</style>
