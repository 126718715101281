<template>
  <div class="appointments-side-page">
    <div class="columns">
      <div class="column col-12 form-group">
        <label for="specialty" class="form-label">Procedimento</label>
        <select
          id="specialty"
          name="specialty"
          class="form-select"
          v-model="filter.expenseId"
        >
          <option value="">[Todos]</option>
          <optgroup
            v-for="group in expenseGroups"
            :label="group.label"
            :key="group.type"
          >
            <option
              v-for="item in group.items"
              :value="item.id"
              :key="item.key"
            >{{ item.name }}</option>
          </optgroup>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label for="insurance" class="form-label">Convênio</label>
        <select
          id="insurance" name="insurance"
          class="form-select"
          v-model="filter.insuranceId"
        >
          <option value="">[Todos]</option>
          <option
            v-for="(item, i) in insurances"
            :value="item.id"
            :key="i"
          >{{ item.customName }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label for="professional" class="form-label">Agenda</label>
        <select
          id="professional"
          name="professional"
          class="form-select"
          v-model="filter.scheduleId"
        >
          <option value="">[Selecione]</option>
          <option value="ALL">[Todos]</option>
          <option
            v-for="(item, i) in schedules"
            :value="item.id"
            :key="i"
          >{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <st-calendar
          :date="dateJS"
          :highlights="highlights"
          @select="selectDate"
          @month-change="monthChange"
          ref="calendar">
        </st-calendar>
      </div>
    </div>
    <div class="columns mt-2 btn-actions-group">
      <div class="column">
        <button class="btn btn-primary btn-block"
                @click="showAppointmentModal()"
                data-tooltip="Novo agendamento">Encaixe</button>
      </div>
      <div class="column">
        <button class="btn btn-primary btn-block"
                @click="openSearch">Pesquisar</button>
      </div>
    </div>
    <div class="columns mt-2 btn-actions-group">
      <div class="column">
        <button class="btn btn-primary btn-block"
                @click="openCommitment">Compromisso</button>
      </div>
      <div class="column">
        <button class="btn btn-primary btn-block"
                @click="openPrint">Imprimir</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapMutations, mapActions, mapState,
} from 'vuex';
import moment from 'moment';
import localforage from 'localforage';
import { types as expenseTypes } from '@/data/expense-types';

export default {
  data() {
    return {
      showPrint: false,
      filter: this.$store.state.appointment.filter,
    };
  },
  beforeCreate() {
    const appointmentState = this.$store.state.appointment;
    appointmentState.filter = appointmentState.blankFilter();
    appointmentState.currentMonth = null;
  },
  async mounted() {
    if (this.user.branch.settings && !this.user.branch.settings.canViewSchedule) {
      this.filter.professionalId = this.user.id;
    }
    if ('date' in this.$route.query && this.$route.query.date) {
      this.filter.date = this.$route.query.date;
    }

    this.$bus.$on('appointment-today', () => this.today());
    this.loadFilters();
    await this.loadFilterState();
    this.loadCalendar(true)
      .then(this.setWatchers);
  },
  methods: {
    setWatchers() {
      this.$watch('filter.date', () => this.loadCalendar(false));
      this.$watch('filter.expenseId', () => {
        this.updateFilters();
        this.loadCalendar(true);
      });
      this.$watch('filter.insuranceId', () => {
        this.updateFilters();
        this.loadCalendar(true);
      });
      this.$watch('filter.scheduleId', () => this.loadCalendar(true));
    },
    loadFilterState() {
      return localforage.getItem('appointmentFilters')
        .then((filters) => {
          if (filters) {
            if (filters.insuranceId) {
              this.filter.insuranceId = filters.insuranceId;
            }
            if (filters.expenseId) {
              this.filter.expenseId = filters.expenseId;
              if (filters.specialtyCode) {
                this.filter.expenseId = [filters.expenseId, filters.specialtyCode].join(':');
              }
            }
            if ('scheduleId' in this.$route.query && this.$route.query.scheduleId) {
              this.filter.scheduleId = this.$route.query.scheduleId;
            } else if (filters.scheduleId) {
              this.filter.scheduleId = filters.scheduleId;
            }
          }
        })
        .catch(() => {});
    },
    updateFilters() {
      if (this.currentExpense) {
        if (
          this.filter.insuranceId
          && !this.currentExpense.insuranceIds.includes(this.filter.insuranceId)
        ) {
          this.filter.insuranceId = '';
        }
        if (
          this.filter.scheduleId
          && this.filter.scheduleId !== 'ALL'
          && !this.currentExpense.scheduleIds.includes(this.filter.scheduleId)
        ) {
          this.filter.scheduleId = 'ALL';
        }
      } else if (this.currentInsurance) {
        if (
          this.filter.scheduleId
          && this.filter.scheduleId !== 'ALL'
          && !this.currentInsurance.scheduleIds.includes(this.filter.scheduleId)
        ) {
          this.filter.scheduleId = 'ALL';
        }
      }
    },
    today() {
      this.filter.date = moment().format('YYYY-MM-DD');
    },
    selectDate({ dateISO }) {
      this.filter.date = dateISO;
    },
    monthChange({ dateISO }) {
      this.setCurrentMonth(dateISO);
      this.loadAvailableDays();
    },
    ...mapMutations({
      showAppointmentModal: 'Appointment.SHOW_APPOINTMENT_MODAL',
      openSearch: 'Appointment.SHOW_SEARCH_APPOINTMENT_MODAL',
      openPrint: 'Appointment.SHOW_PRINT_APPOINTMENT_MODAL',
      openCommitment: 'Appointment.SHOW_COMMITMENT_APPOINTMENT_MODAL',
      setCurrentMonth: 'Appointment.SET_CURRENT_MONTH',
    }),
    ...mapActions({
      loadCalendar: 'loadCalendar',
      loadFilters: 'loadFilters',
      loadAvailableDays: 'loadAvailableDays',
    }),
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
      highlights: state => state.appointment.available.days.map(item => ({
        date: item.date,
        label: `${item.count} ${item.count === 1 ? 'horário' : 'horários'}`,
      })),
    }),
    currentExpense() {
      return this.filter.expenseId
        ? this.expenses.find(({ id }) => id === this.filter.expenseId)
        : null;
    },
    currentInsurance() {
      return this.filter.insuranceId
        ? this.filter.insurances.find(({ id }) => id === this.filter.insuranceId)
        : null;
    },
    expenses() {
      return this.filter.expenses.reduce((result, item) => {
        if (item.specialties.length > 0) {
          item.specialties.forEach((specialty) => {
            result.push({
              ...item,
              name: new RegExp(specialty.name, 'i').test(item.name)
                ? item.name
                : `${item.name} (${specialty.name})`,
              key: [item.id, specialty.code].join(':'),
            });
          });
        } else {
          result.push({ ...item, key: item.id });
        }
        return result;
      }, []);
    },
    expenseGroups() {
      const sortScore = {
        consultation: 2,
        exam: 1,
      };

      return this.expenses
        .reduce((result, item) => {
          let found = result.find(({ type }) => type === item.type);
          if (!found) {
            found = {
              type: item.type,
              label: expenseTypes[item.type],
              score: item.type in sortScore ? sortScore[item.type] : 0,
              items: [],
            };
            result.push(found);
          }
          found.items.push(item);
          return result;
        }, [])
        .sort((a, b) => b.score - a.score);
    },
    insurances() {
      const idsToFilter = [];

      if (this.currentExpense) {
        idsToFilter.push(...this.currentExpense.insuranceIds);
      }

      return idsToFilter.length > 0
        ? this.filter.insurances.filter(({ id }) => idsToFilter.includes(id))
        : this.filter.insurances;
    },
    schedules() {
      const idsToFilter = [];

      if (this.filter.expenseId || this.filter.insuranceId) {
        const found = this.filter.expenseId ? this.currentExpense : this.currentInsurance;
        if (found) {
          idsToFilter.push(...found.scheduleIds);
        }
      }

      return idsToFilter.length > 0
        ? this.filter.schedules.filter(({ id }) => idsToFilter.includes(id))
        : this.filter.schedules;
    },
    dateJS() {
      return moment(this.filter.date).toDate();
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .appointments-side-page {
    padding: $layout-spacing;
    .form-group {
      padding-top: $layout-spacing + .05rem;
      position: relative;
      .form-label {
        background-color: $light-color;
        border-radius: $border-radius;
        color: $gray-color;
        left: $layout-spacing + .25rem;
        font-size: $font-size-xs;
        line-height: normal;
        padding: calc($layout-spacing-sm / 2) $layout-spacing-sm;
        position: absolute;
        top: 0;
        z-index: $zindex-1;
      }
    }
  }
</style>
