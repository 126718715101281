<template>
  <div class="expense-modal">
    <form autocomplete="off" @submit.prevent.stop="addExpense" v-if="!isFinished">
      <div class="columns mb-1 mt-2">
        <div class="column col-3 form-group">
          <select class="form-select" v-model="filterBySchedule">
            <option :value="true">Da agenda</option>
            <option :value="false">Da tabela do convênio</option>
          </select>
        </div>
        <div
          class="column col-9 form-group"
          :class="!canSearch ? 'tooltip' : ''"
          :data-tooltip="!canSearch ? 'Convênio e profissional devem estar selecionados' : ''"
        >
          <dx-autocomplete
            v-model="expense"
            :source="findExpense"
            label="name"
            track-by="id"
            :disabled="!canSearch"
            :debounce="800"
            @select="addAppointmentExpense"
            placeholder="Informe o código ou nome da consulta ou outros..."
            input-id="expense-name"
          >
            <template v-slot="{ item }">
              <a>
                <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                <span class="ml-1">
                  {{ item.code | tuss }} - {{ item.expense.name }}
                </span>
              </a>
            </template>
            <template v-slot:action>
              <button
                type="button"
                @click="openExpenseModal"
                class="btn btn-action input-group-btn btn-icon btn-neutral"
                :class="{loading: expenseModal.loading}"
                tabindex="-1"
                :disabled="!canSearch || expenseModal.loading"
              ><fa-icon :icon="['fal', 'search']" /></button>
            </template>
          </dx-autocomplete>
        </div>
      </div>
    </form>

    <template v-if="groupedAccounts.length > 0">
      <div v-for="(account) in groupedAccounts" :key="account.key">
        <div class="account">
          <div class="columns">
            <div class="column" style="align-self: center">
              <span class="tooltip tooltip-right"
                    :data-tooltip="account.id
                    ? 'Lista de procedimentos finalizados' : 'Lista de procedimentos agendados'">
                <fa-icon
                  :icon="['fal', 'info-circle']"
                  class="text-secondary">
                </fa-icon>
              </span>
              <span class="ml-2">
                {{ account.id ? 'Finalizada' : 'Agendadas' }}
              </span>
              <span class="ml-1 text-bold">{{ total(account) | currency }}</span>
            </div>
            <div id="div-register-service" class="column text-right" v-if="!account.id">
              <button class="btn btn-info"
                      :class="{loading: isWaiting}"
                      :disabled="!isSelected || isWaiting || !form.insurance.plan.settings.id"
                      @click="checkAppointment"
                      v-if="!form.insurance.plan.settings.tiss.enabled && !isFinished">
                Registrar atendimento
              </button>
              <dx-dropdown
                v-if="form.insurance.plan.settings.tiss.enabled && !isFinished"
                :items="referralTypes"
                :disabled="!isSelected || isWaiting  || !form.insurance.plan.settings.id"
                @select="selectReferralType"
                label="name"
                direction="right">
                <button class="btn btn-info"
                        :class="{loading: isWaiting}"
                        :disabled="!isSelected || isWaiting || !form.insurance.plan.settings">
                  Registrar atendimento</button>
              </dx-dropdown>
            </div>
            <div class="column text-right" v-else>
              <button class="btn btn-sm btn-gray btn-icon btn-action tooltip ml-1"
                      data-tooltip="Editar"
                      :disabled="!account.id || deleting || editing || printing"
                      v-if="!isFinished"
                      @click="editAccountModal(account.id)">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
              <button class="btn btn-sm btn-gray btn-action btn-icon tooltip ml-1"
                      data-tooltip="Guia de solicitação"
                      v-if="form.insurance.plan.settings.tiss.enabled"
                      :disabled="!account.id || deleting || editing || printing"
                      @click="printRequest(account.id)">
                <fa-icon :icon="['fal', 'file-medical-alt']"></fa-icon>
              </button>
              <button class="btn btn-sm btn-gray btn-icon btn-action tooltip ml-1"
                      data-tooltip="Imprimir"
                      :disabled="!account.id || deleting || editing || printing"
                      @click="printAccount(account.id)">
                <fa-icon :icon="['fal', 'print']"></fa-icon>
              </button>
              <button class="btn btn-sm btn-gray btn-icon
                btn-action tooltip tooltip-left ml-1"
                      data-tooltip="Excluir conta"
                      v-if="!isFinished"
                      :disabled="!account.id || deleting || printing"
                      @click="removeAccountAppointment(account.id)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div style="margin-left: 1rem; margin-bottom: .4rem">
          <table class="table expense-modal-table"
                 :class="account.id ? 'account-finished' : 'table-hover'">
            <tbody>
            <tr v-for="(expense) in account.expenses" :key="expense.key">
              <td class="text-center" style="width: 40px">
                <template v-if="expense.accountId !== null">
                  <fa-icon :icon="['fal', 'file-medical-alt']"></fa-icon>
                </template>
                <template v-else>
                  <div class="form-group" v-if="!isFinished">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="expense.selected">
                      <i class="form-icon"></i>
                    </label>
                  </div>
                </template>
              </td>
              <td>{{ expense.code | tuss }} - {{ expense.name }}</td>
              <td style="width: 100px">
                <label class="form-switch" v-if="expense.type === 'consultation'">
                  <input type="checkbox" v-model="expense.return"
                         :disabled="account.id" @change="changeExpenseReturn">
                  <i class="form-icon"></i>Retorno
                </label>
                <button class="btn btn-sm btn-gray btn-action btn-icon tooltip"
                        data-tooltip="Preparo" v-else
                        @click="getPreparation(expense.id)">
                  <fa-icon :icon="['fal', 'info-circle']"></fa-icon>
                </button>
              </td>
              <td class="text-center" style="width: 120px">
                <span v-if="account.id">x{{expense.quantity}}</span>
                <span v-else>
                  <st-input-number-step
                    v-model="expense.quantity"
                    :min-value="1"
                    :max-value="20"
                  />
                </span>
              </td>
              <td class="text-right" style="width: 80px">
                <small>Unitário</small><br>
                <span v-if="expense.return">R$ 0,00</span>
                <span v-else>{{ expense.value.particular | currency }}</span>
              </td>
              <td class="text-right" style="width: 80px">
                <small>Total</small><br>
                <span v-if="expense.return">R$ 0,00</span>
                <span v-else>
                  {{ ((expense.factor ? expense.factor : 1)
                  * expense.value.particular * expense.quantity) | currency }}
                </span>
              </td>
              <td class="text-right" style="width: 50px" v-if="!isFinished">
                <button class="btn btn-sm btn-error btn-action btn-icon tooltip"
                        data-tooltip="Excluir"
                        @click="deleteAppointmentExpense(expense)"
                        v-if="!expense.accountId">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <dx-modal title="Informações de preparo"
                    v-model="preparationModal.show" size="lg"
                    v-if="preparationModal.show"
                    id="modal-preparation">
            <div class="preparation-content" v-html="preparation" />
            <template slot="footer">
              <button class="btn btn-gray mr-1"
                      :class="{loading: preparationModal.printing}"
                      :disabled="preparationModal.printing"
                      @click="print">
                <fa-icon :icon="['fal', 'print']" />
                Imprimir
              </button>
              <button class="btn" @click="preparationModal.show = false">Sair</button>
            </template>
          </dx-modal>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Procedimentos</p>
        <p class="empty-subtitle">
          Nenhum procedimento encontrado. Informe o código ou nome no campo acima.
        </p>
      </div>
    </template>

    <dx-modal
      title="Lista de procedimentos"
      v-model="expenseModal.show" size="lg"
      v-if="expenseModal.show"
      id="modal-expenses-appointment"
    >
      <div class="loading loading-lg mt-2" v-if="expenseModal.loading" />
      <template v-else>
        <div class="columns">
          <div class="column col-3 form-group">
            <select class="form-select" v-model="filterBySchedule" @change="searchExpenseModal">
              <option :value="true">Da agenda</option>
              <option :value="false">Da tabela do convênio</option>
            </select>
          </div>
          <div class="column col-9 form-group">
            <div class="input-group">
              <input
                type="text"
                class="form-input"
                id="search"
                v-model="expenseModal.search"
                v-focus
                @keydown.enter="searchExpenseModal"
                placeholder="Digite parte do nome ou código e tecle [Enter]"
              >
                <button
                  class="btn btn-neutral btn-icon btn-action input-group-btn"
                  :disabled="expenseModal.loading"
                  :class="{loading: expenseModal.loading}"
                  @click="searchExpenseModal"
                ><fa-icon :icon="['fal', 'search']" />
              </button>
            </div>
          </div>
        </div>
        <div class="empty mt-2"
             v-if="expenseModal.data.items.length === 0 && !expenseModal.loading">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
          </div>
          <p class="empty-title h6">Procedimentos</p>
          <p class="empty-subtitle">
            Nenhum procedimento encontrado para esta agenda. Verifique as configurações.
          </p>
        </div>
        <table class="table table-hover expense-modal-table" v-else>
          <thead>
          <tr>
            <th style="width: 85px">Código</th>
            <th>Nome</th>
            <th class="text-right">Valor Paciente</th>
          </tr>
          </thead>
          <tbody>
          <tr class="c-hand" v-for="(item, i) in expenseModal.data.items" :key="i"
              @click="selectExpense(item)">
            <td>
              <span v-if="item.code">{{ item.code | tuss }}</span>
              <span v-else>-</span>
            </td>
            <td>{{ item.expense.name | capitalizeWord }}</td>
            <td class="text-right">
              {{ item.definition.particularValue | currency }}
            </td>
          </tr>
          </tbody>
        </table>
        <div class="load-more" v-if="expenseModal.data.items.length > 0">
          <button
            v-if="expenseModal.data.hasMore && !loading"
            class="btn btn-primary"
            @click="loadMore"
            :disabled="expenseModal.loadingMore"
            :class="{ loading: expenseModal.loadingMore }"
          >Carregar mais...</button>
        </div>
      </template>
      <template slot="footer">
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
    <referral-modal :show="account.show"
                    :data="account.data"
                    :source="source"
                    :type="account.type"
                    v-if="account.show"
                    @close="hideAccountModal" />
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import moment from 'moment';
import ReferralModal from './Referral.vue';

export default {
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ReferralModal,
  },
  data() {
    return {
      source: 'appointments',
      editing: false,
      printing: false,
      deleting: false,
      loading: false,
      expense: null,
      referralType: null,
      filterBySchedule: true,
      expenseModal: {
        loading: false,
        loadingMore: false,
        show: false,
        search: '',
        data: {
          items: [],
          hasMore: false,
          limit: 30,
          offset: 0,
        },
      },
      preparationModal: {
        show: false,
        printing: false,
        expenseId: '',
        preparation: '',
      },
      account: {
        type: '',
        data: {},
        show: false,
      },
      form: {},
    };
  },
  created() {
    this.form = this.$store.state.appointment.form;
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    groupedAccounts() {
      return this.form.expenses
        .reduce((result, expense) => {
          let found = result.find(({ id }) => expense.accountId === id);
          if (!found) {
            found = {
              id: expense.accountId,
              key: expense.accountId || 'no-account',
              expenses: [],
            };
            result.push(found);
          }
          found.expenses.push(expense);
          return result;
        }, [])
        .sort((a, b) => {
          if (!a.id) return -1;
          if (!b.id) return 1;
          return 0;
        });
    },
    isSelected() {
      return this.form.expenses
        .some(item => item.accountId === null && item.selected);
    },
    isWaiting() {
      return this.deleting || this.loading || this.saving;
    },
    canSearch() {
      return this.form.insurance.plan.id && this.form.professional.id;
    },
    preparation() {
      if (!this.preparationModal.preparation) return '';

      return this.preparationModal.preparation
        .replace(/(<figure([^>]+)>)/ig, '')
        .replace(/<\/figure>/ig, '')
        .replace(/<table>/ig, '<table class="table">');
    },
    referralTypes() {
      const sadt = this.form.insurance.plan.settings.tiss.consultationInSadt;
      const items = [];
      if (!sadt) {
        items.push({ code: 'consultation', name: 'Guia de Consulta' });
      }
      items.push({ code: 'sadt', name: 'Guia de SP/SADT' });
      return items;
    },
    isFinished() {
      const allowEditFinishedAppointment = this.user.branch.settings
      && this.user.branch.settings['clinical.allowEditFinishedAppointment']
        ? this.user.branch.settings['clinical.allowEditFinishedAppointment']
        : false;
      return !!this.form.service.finishedAt && !allowEditFinishedAppointment;
    },
  },
  methods: {
    ...mapMutations({
      showAccountModal: 'Appointment.SHOW_ACCOUNT_MODAL',
      addExpense: 'Appointment.ADD_EXPENSE',
      deleteExpense: 'Appointment.DELETE_EXPENSE',
      setAccount: 'Appointment.SET_ACCOUNT',
    }),
    total(account) {
      return account.expenses
        .reduce((a, b) => a + Number(b.value.particular
          * b.quantity * (b.factor ? b.factor : 1)), 0);
    },
    findExpense(search) {
      const params = {
        search,
        insuranceId: this.form.insurance.id,
        planId: this.form.insurance.plan.id,
        limit: 30,
        offset: 0,
      };

      if (this.filterBySchedule) {
        params.scheduleId = this.form.schedule.id;
      }

      return this.$http
        .get(`/professionals/${this.form.professional.id}/expense-prices`, { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: null,
              expense: {
                id: null,
                type: null,
                name: 'Procedimento não encontrado neste convênio',
              },
              quantity: 0,
              factor: 0,
              definition: {
                insuranceValue: 0,
                particularValue: 0,
              },
            }];
          }
          return data.items;
        });
    },
    addAppointmentExpense(expense) {
      if (!expense || expense.expense.id === null) {
        return;
      }

      const found = this.form.expenses
        .find(item => (item.id === expense.expense.id && !item.accountId));
      if (found) {
        found.quantity += 1;
      } else {
        this.addExpense({
          id: expense.expense.id,
          selected: true,
          code: expense.code,
          type: expense.expense.type,
          return: false,
          name: expense.expense.name,
          specialty: expense.specialty || null,
          quantity: 1,
          factor: 1,
          value: {
            insurance: expense.definition.insuranceValue,
            particular: expense.definition.particularValue,
          },
          accountId: null,
        });
        if (expense.definition.insuranceValue + expense.definition.particularValue === 0) {
          this.$toast.show('Atenção! Procedimento ainda não precificado.', { type: 'error' });
        }
        this.$emit('validateScheduleInfo');
      }

      if (expense.hasPreparation) {
        this.getPreparation(expense.expense.id);
      }

      this.expense = null;
    },
    print() {
      this.preparationModal.printing = true;
      return this.$file
        .print(`/expenses/${this.preparationModal.expenseId}/print-preparation`)
        .catch(() => {})
        .finally(() => {
          this.preparationModal.printing = false;
        });
    },
    selectReferralType(item) {
      this.referralType = item.code;
      this.checkAppointment();
    },
    openExpenseModal() {
      this.expenseModal.search = '';
      this.searchExpenseModal();
      this.expenseModal.show = true;
    },
    searchExpenseModal() {
      this.expenseModal.data.items = [];
      this.expenseModal.data.hasMore = false;
      this.expenseModal.data.offset = 0;
      this.loadExpenseModalItems();
    },
    async loadExpenseModalItems() {
      const firstData = this.expenseModal.data.offset === 0;

      if (firstData) {
        this.expenseModal.loading = true;
      } else {
        this.expenseModal.loadingMore = true;
      }

      const params = {
        limit: this.expenseModal.data.limit,
        offset: this.expenseModal.data.offset,
        insuranceId: this.form.insurance.id,
        planId: this.form.insurance.plan.id,
      };

      if (this.expenseModal.search) {
        params.search = this.expenseModal.search;
      }

      if (this.filterBySchedule) {
        params.scheduleId = this.form.schedule.id;
      }

      this.$http.get(`/professionals/${this.form.professional.id}/expense-prices`, { params })
        .then(({ data }) => {
          this.expenseModal.data.hasMore = data.hasMore;
          this.expenseModal.data.items = firstData
            ? data.items
            : [...this.expenseModal.data.items, ...data.items];
        })
        .catch(() => {})
        .finally(() => {
          this.expenseModal.loading = false;
          this.expenseModal.loadingMore = false;
        });
    },
    async loadMore() {
      this.expenseModal.data.offset += this.expenseModal.data.limit;
      await this.loadExpenseModalItems();
    },
    selectExpense(item) {
      this.addAppointmentExpense(item);
      this.expenseModal.show = false;
    },
    deleteAppointmentExpense(expense) {
      this.deleteExpense(expense);
      this.changeExpenseReturn();
    },
    hideAccountModal() {
      this.account.show = false;
    },
    checkAppointment() {
      this.$emit('check-appointment');
    },
    changeExpenseReturn() {
      this.$emit('validateScheduleInfo');
    },
    async openAccount() {
      const tiss = this.referralType;

      let recordLength = 0;
      if (this.form.insurance.record) {
        recordLength = this.form.insurance.record.length;
      }
      if (this.form.insurance.plan.settings.record.required
        && this.form.insurance.plan.settings.record.requiredValidity) {
        if (recordLength === 0 && !this.form.insurance.validity) {
          this.$toast.show(
            'Informe o número e a validade da carteirinha do paciente na aba Agendamento!',
            { type: 'error' },
          );
          return;
        }
      }
      if (this.form.insurance.plan.settings.record.required) {
        if (recordLength === 0) {
          this.$toast.show('Informe a carteirinha do paciente na aba Agendamento!', { type: 'error' });
          return;
        }
      }
      if (this.form.insurance.plan.settings.record.requiredValidity) {
        if (!this.form.insurance.validity) {
          this.$toast.show('Informe a validade da carteirinha do paciente na aba Agendamento!', { type: 'error' });
          return;
        }
      }
      if (this.form.insurance.plan.settings.record.digits.enabled) {
        if (recordLength < this.form.insurance.plan.settings.record.digits.min) {
          this.$toast.show(`A carteirinha deve ter no mínimo ${this.form.insurance.plan.settings.record.digits.min} dígito(s)!`, { type: 'error' });
          return;
        }
        if (recordLength > this.form.insurance.plan.settings.record.digits.max) {
          this.$toast.show(`A carteirinha deve ter no máximo ${this.form.insurance.plan.settings.record.digits.max} dígito(s)!`, { type: 'error' });
          return;
        }
      }

      this.loading = true;

      let endDate = moment(this.form.startDate)
        .add(15, 'minutes')
        .format('YYYY-MM-DD[T]HH:mm');

      if (this.form.endDate && !moment(this.form.startDate).isSame(this.form.endDate)) {
        endDate = moment(this.form.endDate);
      }

      const account = {
        id: '',
        appointmentId: this.form.id,
        professionalId: this.form.professional.id,
        specialtyCode: this.form.professional.specialty || null,
        key: this.form.key,
        startDate: this.form.startDate,
        endDate,
        patient: {
          id: this.form.patient.id,
          name: this.form.patient.name,
          birthDate: this.form.patient.birthDate,
          gender: this.form.patient.gender,
          cns: this.form.patient.cns ? this.form.patient.cns : '',
          insurance: {
            id: this.form.insurance.id,
            name: this.form.insurance.name || this.form.patient.insurance.name,
            plan: this.form.insurance.plan,
            insured: {
              record: this.form.insurance.record,
              validity: this.form.insurance.validity,
            },
          },
        },
        expenses: this.form.expenses
          .filter(({ selected }) => selected)
          .map((expense) => {
            expense.value.discount = 0;
            expense.value.interest = 0;
            expense.value.tax = 0;
            expense.value.particular = expense.value.particular || 0;
            expense.value.insurance = expense.value.insurance || 0;
            expense.value.total = expense.value.particular + expense.value.insurance;

            const item = {
              id: expense.id,
              startDate: this.form.startDate,
              endDate,
              execution: {
                date: moment(this.form.startDate).format('YYYY-MM-DD'),
                time: moment(this.form.startDate).format('HH:mm'),
                duration: moment.duration(moment(endDate)
                  .diff(moment(this.form.startDate))).asMinutes(),
              },
              accountId: '',
              code: expense.code,
              type: expense.type,
              return: expense.return || false,
              name: expense.name,
              rules: {
                requireAuthorization: false,
                requireClinicalIndication: false,
                requireWayTechnique: false,
                definition: {},
              },
              quantity: expense.quantity,
              factor: expense.factor ? expense.factor : 1,
              way: '',
              technique: '',
              value: expense.value,
              professional: {},
            };

            if (!expense.professional) {
              item.professional = {
                id: this.form.professional.id,
                name: this.form.professional.name,
                council: this.form.professional.council,
                specialty: {
                  code: this.form.professional.specialty,
                },
                degreeParticipation: 'clinical',
              };
            }
            return item;
          }),
      };

      const params = {
        expenseIds: account.expenses.map(({ id }) => id).join(','),
      };
      await this.$http.get(`/expense-insurances/${this.form.insurance.plan.id}/rules`, { params })
        .then(({ data }) => {
          if (data.items) {
            if (data.items.length > 0) {
              data.items.forEach((item) => {
                account.expenses.forEach((expense) => {
                  if (item.id === expense.id) {
                    if (item.rules) {
                      expense.rules = item.rules;
                    }
                  }
                });
              });
            }
          }
        });

      if (tiss) {
        if (this.form.insurance.plan.settings.tiss.consultationInSadt) {
          this.account.type = 'sadt';
        } else {
          this.account.type = tiss;
        }
      } else {
        this.account.type = this.form.insurance.type;
      }
      this.account.data = account;
      this.account.show = true;
      this.loading = false;
    },
    async printRequest(accountId) {
      this.printing = true;
      return this.$file
        .print(`/billing-accounts/${accountId}/printRequest`)
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.error(e.response.data.message, { timeout: 5000 });
          } else {
            this.$toast.error('Não foi possível realizar a impressão!');
          }
        })
        .finally(() => {
          this.printing = false;
        });
    },
    async printAccount(accountId) {
      this.printing = true;
      return this.$file
        .print(`/billing-accounts/${accountId}/print`)
        .then(() => {
          this.printing = false;
        });
    },
    async editAccountModal(accountId) {
      this.editing = true;
      let account;
      await this.$http.get(`/billing-accounts/${accountId}`)
        .then(({ data }) => {
          const professional = data.expenses.length > 0
            ? data.expenses[0].professional
            : null;
          if (data.invoice && data.invoice.id) {
            data.invoiceId = data.invoice.id;
          }
          data.professionalId = professional.id;
          data.specialtyCode = professional.specialty
            ? professional.specialty.code
            : null;
          data.key = this.form.key;
          data.expenses.forEach((expense) => {
            expense.accountId = accountId;
            expense.execution = {
              date: moment(expense.startDate).format('YYYY-MM-DD'),
              time: moment(expense.startDate).format('HH:mm'),
              duration: moment.duration(moment(expense.endDate)
                .diff(moment(expense.startDate))).asMinutes(),
            };
            expense.rules = {
              requireAuthorization: false,
              requireClinicalIndication: false,
              requireWayTechnique: false,
            };
          });
          account = data;
        });

      if (this.form.insurance.plan.settings.tiss.enabled) {
        const params = {
          expenseIds: account.expenses.map(({ id }) => id).join(','),
        };
        await this.$http.get(`/expense-insurances/${this.form.insurance.plan.id}/rules`, { params })
          .then(({ data }) => {
            if (data.items) {
              if (data.items.length > 0) {
                data.items.forEach((item) => {
                  account.expenses.forEach((expense) => {
                    if (item.id === expense.id) {
                      if (item.rules) {
                        expense.rules = item.rules;
                      }
                    }
                  });
                });
              }
            }
          });
        if (this.form.insurance.plan.settings.tiss.consultationInSadt) {
          this.account.type = 'sadt';
        } else {
          this.account.type = account.referral.type;
        }
      } else {
        this.account.type = account.patient.insurance.type;
      }
      this.account.data = account;
      this.account.show = true;
      this.editing = false;
    },
    removeAccountAppointment(id) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente cancelar esta conta e estornar os procedimentos?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http
                .delete(`/billing-accounts/${id}`)
                .then(() => {
                  this.$store.state.appointment.form.status = 'scheduled';
                  this.form.expenses.forEach((expense) => {
                    if (id === expense.accountId) {
                      expense.accountId = null;
                      expense.value.discount = 0;
                      expense.value.interest = 0;
                      expense.value.tax = 0;
                      expense.value.rate = 0;
                      expense.selected = false;
                    }
                  });
                  this.setAccount({
                    key: this.form.key,
                    expenses: this.form.expenses,
                  });
                  close();
                })
                .catch((e) => {
                  if (e.response
                    && e.response.data
                    && e.response.data.message) {
                    this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
                    close();
                  }
                })
                .then(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    getPreparation(id) {
      this.$http.get(`/procedures/${id}/preparation`)
        .then(({ data }) => {
          if (data.preparation) {
            this.preparationModal.expenseId = id;
            this.preparationModal.preparation = data.preparation;
            this.preparationModal.show = true;
          } else {
            this.$toast.show('Procedimento sem preparo cadastrado', { type: 'error' });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.expense-modal {
  .account {
    background-color: #eee;
    padding: .4rem;
    border: 1px solid $border-color;
  }
  .account-finished {
    background-color: lighten($gray-color-light, 4%);
  }
  .expense-modal-table {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    &.table-hover {
      tr:hover {
        background-color: lighten($border-color, 6%);
      }
    }
  }
  #div-register-service li a:hover {
    background-color: $gray-color-light;
    color: black;
  }
}

#modal-expenses-appointment {
  .load-more {
    margin: $layout-spacing-lg 0;
    text-align: center;
    .btn {
      padding: 0 $layout-spacing-xl;
    }
  }
}

#modal-preparation {
  .preparation-content {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-lg;
    p {
      margin-bottom: $layout-spacing;
    }
  }
}
</style>
